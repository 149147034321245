<template>
  <CBox>
    <CHeading color="fontMain" size="2xl">{{ label }}</CHeading>
    <CDivider borderColor="dividerColor" />
  </CBox>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: "Dashboard Web CV"
      }
    }
  }
</script>